import { css } from "@shiftx/styles"
import { Section } from "./Section"
import { Title2, Title4 } from "./Text"
import { lessThan } from "../utils/breakpoints"
import Link from "next/link"
import { Paragraph } from "./Text"
import { Image } from "./Image"
import { BlockHeaderSpacer } from "./Spacer"

export const GotoUsecases = ({
    content,
}: {
    content: {
        title?: string
        useCases: Array<{
            slug?: {
                current: string
            }
            title: string
            short?: string
            thumb?: any
        }>
    }
}) => {
    return (
        <Section
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
            `}
        >
            <Title2
                css={css`
                    color: transparent;
                    text-align: center;
                    background: linear-gradient(
                        45deg,
                        var(--fg-high) 0%,
                        var(--fg-medium) 100%
                    );
                    background-size: 100%;
                    background-clip: text;
                    display: block;
                `}
            >
                {content.title}
            </Title2>
            <BlockHeaderSpacer />
            <div
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: stretch;
                    flex-wrap: wrap;
                    width: 100%;
                    ${lessThan(0)`
                        flex-direction: column;
                        height: 100%;
                    `}
                `}
            >
                {content.useCases.map((item, index) => {
                    return (
                        <Link
                            key={index}
                            href={`/use-case/${item.slug.current}`}
                            css={css`
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: center;
                                text-align: center;
                                max-width: 400px;
                                -webkit-font-smoothing: antialiased;
                                margin: 0 40px 40px;
                                :hover {
                                    --hover-border: var(--fg-200);
                                    --hover-title: var(--fg-high);
                                }
                            `}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                `}
                            >
                                <Title4
                                    as="h3"
                                    css={css`
                                        font-weight: 600;
                                        margin-top: 16px;
                                        color: var(
                                            --hover-title,
                                            var(--fg-medium)
                                        );
                                    `}
                                >
                                    {item.title}
                                </Title4>
                                <Paragraph
                                    css={css`
                                        margin-top: 8px;
                                        font-size: 16px;
                                        line-height: 28px;
                                        font-weight: 400;
                                        text-wrap: balance;
                                        transition: color 0.2s ease;
                                        color: var(--fg-medium);
                                        width: 240px;
                                    `}
                                >
                                    {item.short}
                                </Paragraph>
                            </div>
                            <Image
                                key={item.title}
                                image={item.thumb[0]}
                                alt={item.thumb[0]?.alt}
                                css={css`
                                    border-radius: 14px;
                                    transition: all 0.2s ease;
                                    border: 1px solid
                                        var(--hover-border, transparent);
                                `}
                            />
                        </Link>
                    )
                })}
            </div>
        </Section>
    )
}
